import React from "react";

import Layout from "../layout";
import SEO from "../components/seo";
import Heading from "../components/atoms/Heading/Heading";
import Paragraph from "../components/atoms/Paragraph/Paragraph";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Strona nie została znaleziona" />
        <Heading>Nie znaleziono</Heading>
        <Paragraph>Strona, której szukasz, nie istnieje...</Paragraph>
    </Layout>
);

export default NotFoundPage;
